<template>
  <swiper
    :modules="modules"
    :navigation="true"
    :loop="true"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    class="mySwiper"
  >
    <swiper-slide v-for="item in banner" :key="item.id">
      <div class="banner">
        <img
          :src="item.url"
          class="b-img"
          style="width: 100%"
          alt="百安国医馆"
        />
      </div>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { bannerApi } from "@/api/banner/banner.js";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const modules = ref([Pagination, Navigation, Autoplay]);
let banner = ref([]);

//  获取数据
onMounted(() => {
  bannerApi()
    .getList()
    .then((res) => {
      banner.value = res.data;
    })
    .catch((error) => {
      console.error(error);
    });
});
</script>

<style lang="scss" scoped>
.mySwiper {
  max-width: 75%;
  min-width: 1424px;
  height: 100%;
  .banner {
    margin: 0 auto;
    .b-img {
      width: 100%;
      height: 100%;
    }
  }
}

:deep(.swiper-button-prev) {
  color: #00b2b3;
}
:deep(.swiper-button-next) {
  color: #00b2b3;
}
</style>
