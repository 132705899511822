<template>
  <div class="famous-doctor-class">
    <div class="divider">名医讲堂</div>

    <div class="class-container">
      <swiper
        :modules="modules"
        :navigation="true"
        :slidesPerView="4"
        :spaceBetween="52"
        :loop="true"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
      >
        <swiper-slide
          class="swiper-slide"
          v-for="item in videoList"
          :key="item"
          @click="openDialog(item)"
        >
          <img
            style="width: 100%; height: 100%"
            :src="item.url"
            alt="名医讲堂"
          />
          <div class="player-btn"></div>
        </swiper-slide>
      </swiper>
    </div>

    <el-dialog
      v-model="dialogVisible"
      title="名医讲堂"
      width="1400"
      @closed="closeDialog"
    >
      <div class="video-container" id="video"></div>
    </el-dialog>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import { ref, nextTick } from "vue";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import Player from "xgplayer";
import "xgplayer/dist/index.min.css";
import { onMounted } from "vue";
import { api } from "@/api/famousDoctorClass/famousDoctorClass.js";

const modules = ref([Pagination, Navigation, Autoplay]);
let dialogVisible = ref(false);

const videoList = ref([]);

onMounted(() => {
  api()
    .getList()
    .then((res) => {
      videoList.value = res.data;
    });
});

let player = ref(null);

const openDialog = (video) => {
  dialogVisible.value = true;
  nextTick(() => {
    player.value = new Player({
      id: "video",
      url: video.cover,
      width: 1368,
      height: 800,
      autoplay: true,
    });
  });
};

const closeDialog = () => {
  player.value.destroy();
  player.value = null;
};
</script>

<style lang="scss" scoped>
.famous-doctor-class {
  width: 100%;
  height: 348px;
  margin-top: 40px;

  .divider {
    width: 100%;
    height: 44px;
    font-size: 20px;
    color: #787676;
    text-align: center;
    margin: 40px 0 0;
    line-height: 44px;
    font-weight: bold;
    letter-spacing: 9px;
  }

  .class-container {
    width: 1424px;
    height: 268px;
    margin: 20px auto 0;
    :deep(.swiper-button-prev) {
      color: #00b2b3;
    }
    :deep(.swiper-button-next) {
      color: #00b2b3;
    }
  }

  .swiper-slide {
    cursor: pointer;
    position: relative;
    background: #00b2b3;
    height: 227px;
    .player-btn {
      position: absolute;
      width: 317px;
      height: 227px;
      background: url("@/assets/home/videoBtn.png") no-repeat;
      background-size: 100% 100%;
      top: 0;
    }
  }
}

:deep(.el-dialog) {
  margin-top: 3vh;
}
.video-container {
  height: 800px;
}
</style>
