<template>
  <div class="home">
    <Header />
    <div class="nav">
      <div class="tab-container">
        <el-tabs
          v-model="activeName"
          class="demo-tabs"
          @tab-click="handleTabClick"
        >
          <el-tab-pane label="首页" name="home"></el-tab-pane>
          <el-tab-pane label="医馆简介" name="hospitalIntroduce"></el-tab-pane>
          <el-tab-pane label="新闻动态" name="news"></el-tab-pane>
          <el-tab-pane label="专家团队" name="doctor"></el-tab-pane>
          <el-tab-pane
            label="特色专病"
            name="characteristicDepartment"
          ></el-tab-pane>
          <el-tab-pane
            label="非物质文化遗产-胡氏儿科"
            name="hushiChild"
          ></el-tab-pane>
          <el-tab-pane label="特色项目" name="specialProject"></el-tab-pane>
          <el-tab-pane label="便民服务" name="handyServe"></el-tab-pane>
          <el-tab-pane label="名医讲堂" name="famousDoctor"></el-tab-pane>
          <el-tab-pane label="党建工作" name="partyBuild"></el-tab-pane>
          <!-- <el-tab-pane
            label="医馆环境"
            name="hospitalEnvironment"
          ></el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
    <!-- <router-view /> -->
    <div>
      <Banner id="home" />
      <div class="center-content">
        <HospitalIntroduce id="hospitalIntroduce" />
        <News id="news" />
      </div>
      <Doctor id="doctor" />
      <CharacteristicDepartment id="characteristicDepartment" />
      <HushiChild id="hushiChild" />
      <SpecialProject id="specialProject" />
      <HandyServe id="handyServe" @goToEnd="goToEnd" />
      <FamousDoctor id="famousDoctor" />
      <PartyBuild id="partyBuild" />
      <!-- <HospitalEnvironment id="hospitalEnvironment" /> -->
      <Map />
      <aboutUs />
    </div>
  </div>
</template>

<script setup>
import Header from "@/views/component/Header.vue";
// import Nav from "@/views/component/Nav.vue";
import HeaderNav from "@/views/component/HeaderNav.vue";

import Banner from "@/views/home/Banner.vue";
import HospitalIntroduce from "@/views/home/HospitalIntroduce.vue";
import News from "@/views/home/News.vue";
import Doctor from "@/views/home/Doctor.vue";
import CharacteristicDepartment from "@/views/home/CharacteristicDepartment.vue";
import aboutUs from "@/views/home/aboutUs.vue";
import HushiChild from "@/views/home/HuShiChildDepart.vue";
import SpecialProject from "@/views/home/SpecialProject.vue";
import HandyServe from "@/views/home/HandyServe.vue";
import FamousDoctor from "@/views/home/FamousDoctorClass.vue";
import PartyBuild from "@/views/home/PartyBuild.vue";
import HospitalEnvironment from "@/views/home/HospitalEnvironment.vue";
import Map from "@/views/home/Map.vue";
import { toRefs, onMounted, ref, nextTick } from "vue";
import { useRoute } from "vue-router";

const activeName = ref("home");

// 页面滑动
const handleTabClick = (tab) => {
  let element;
  if (typeof tab !== "string") {
    const refName = tab.props.name;
    element = document.getElementById(refName);
  } else {
    element = document.getElementById(tab);
  }
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

// 页面路由名称
const navName = ref("home");

const goToEnd = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: "smooth", // 可选，平滑滚动
  });
};

// 使用 useRoute 获取参数
const route = useRoute();

// DOM加载完成后执行
onMounted(() => {
  // 解构出来的不是响应式，需要用toRefs使其变成响应式
  let { query } = toRefs(route);
  if (query.value.refName) {
    activeName.value = query.value.refName;

    // 用于在下次 DOM 更新循环结束之后执行延迟回调
    // nextTick(() => {
    //   handleTabClick(query.value.refName);
    // });
    setTimeout(() => {
      handleTabClick(query.value.refName);
    }, 100);
  }
});
</script>

<style lang="scss" scoped>
.home {
  min-width: 1440px;
  .nav {
    width: 100%;
    height: 60px;
    margin-top: 10px;
    background-color: #00b2b3;
    .tab-container {
      width: 1400px;
      height: 100%;
      margin: 0 auto;
      :deep(.el-tabs__content) {
        display: none;
      }
      :deep(.el-tabs__header) {
        margin: 0;
      }
      :deep(.el-tabs__item) {
        height: 60px;
        color: white;
        padding: 0 29px !important; // 修改导航栏内容的左右内边距
        font-size: 18px;
        font-weight: bold;
      }
      :deep(.el-tabs__nav-wrap:after) {
        background: transparent;
      }
      :deep(.el-tabs__item.is-active, .el-tabs__item:hover) {
        color: #00b2b3 !important;
        background-color: white !important;
      }
      :deep(.el-tabs__active-bar) {
        background: #00b2b3;
      }
      :deep(.el-tabs__nav-next) {
        line-height: 60px;
        color: white;
      }
      :deep(.el-tabs__nav-prev) {
        line-height: 60px;
        color: white;
      }
    }
  }
  .center-content {
    width: 1424px;
    margin: 0 auto;
  }
}

//  媒体查询
// @media screen and (max-width: 768px) {
//   .home {
//     display: none;
//   }
// }
</style>
