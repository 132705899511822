import request from "@/utils/request";

/**
 * 党建工作接口
 *
 */
export function api() {
  return {
    getList: () => {
      return request({
        url: "/website/architecture/front/list",
        method: "get",
      });
    },
    getDetail: (id) => {
      return request({
        url: "/website/architecture/front/detail",
        method: "get",
        params: {
          id,
        },
      });
    },
    getOther: (id) => {
      return request({
        url: "/website/architecture/front/randomNews",
        method: "get",
        params: {
          id,
        },
      });
    },
    getAll: (id) => {
      return request({
        url: "/website/architecture/front/all",
        method: "get",
      });
    },
  };
}
