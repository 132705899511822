<template>
  <div class="header-container">
    <div class="logo"></div>
    <div class="share-btn" @click="share">分享给朋友</div>
    <div class="company-message">
      <div class="tel"></div>
      <div class="code"></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { ElNotification } from "element-plus";

// 创建一个响应式变量来存储当前路径
const currentPath = ref(window.location.href);

const share = async () => {
  try {
    // 检查浏览器是否支持 clipboard API
    if (navigator.clipboard) {
      // 复制 currentPath 的值到剪贴板
      await navigator.clipboard.writeText(currentPath.value);
      ElNotification({
        title: "复制成功",
        message: "已复制当前链接，可以分享给朋友了！",
        type: "success",
      });
    } else {
      // 回退方案：使用 document.execCommand('copy')
      const textarea = document.createElement("textarea");
      textarea.value = currentPath.value;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        ElNotification({
          title: "复制成功",
          message: "已复制当前链接，可以分享给朋友了！",
          type: "success",
        });
      } catch (err) {
        console.error("复制失败:", err);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  } catch (err) {
    console.error("复制失败:", err);
  }
};
</script>

<style lang="scss">
.header-container {
  height: 100px;
  position: relative;
  min-width: 1200px;
  .logo {
    position: absolute;
    width: 435px;
    height: 90px;
    top: 16px;
    left: 180px;
    background: url("@/assets/home/logo.png") no-repeat;
  }

  .share-btn {
    cursor: pointer;
    position: absolute;
    top: 38px;
    right: 580px;
    padding: 10px 20px;
    background-color: #00b2b3;
    color: azure;
    border-radius: 10px;
  }
  .share-btn:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.685); /* 添加阴影效果 */
  }

  .company-message {
    position: absolute;
    top: 10px;
    right: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    .tel {
      width: 173px;
      height: 41px;
      background: url("@/assets/home/tel.png") no-repeat;
    }
    .code {
      width: 189px;
      height: 93px;
      margin-left: 20px;
      background: url("@/assets/home/code.png") no-repeat;
    }
  }
}
</style>
