<template>
  <div class="server-container">
    <div class="divider">便民服务</div>
    <el-scrollbar style="height: 212px">
      <div class="serve-list">
        <div class="list-item" @click="toDetail('预约挂号')">
          <div class="project-img">
            <img
              :src="require('@/assets/home/bookingRegister.png')"
              alt="预约挂号"
            />
          </div>
          <p class="serve-name">预约挂号</p>
        </div>
        <div class="list-item" @click="toDetail('医保定点')">
          <div class="project-img">
            <img
              :src="require('@/assets/home/medicalInsurance.png')"
              alt="医保定点"
            />
          </div>
          <p class="serve-name">医保定点</p>
        </div>
        <div class="list-item" @click="toDetail('中药代煎')">
          <div class="project-img">
            <img :src="require('@/assets/home/Brewing.png')" alt="中药代煎" />
          </div>
          <p class="serve-name">中药代煎</p>
        </div>
        <div class="list-item" @click="toDetail('中药代发')">
          <div class="project-img">
            <img
              :src="require('@/assets/home/DeliverGoods.png')"
              alt="中药代发"
            />
          </div>
          <p class="serve-name">中药代发</p>
        </div>
        <div class="list-item" @click="toDetail('自主续方')">
          <div class="project-img">
            <img :src="require('@/assets/home/Following.png')" alt="自主续方" />
          </div>
          <p class="serve-name">自主续方</p>
        </div>
        <div class="list-item" @click="toEnd">
          <el-tooltip
            class="box-item"
            effect="dark"
            content="请扫描网页最下方的二维码联系我们"
            placement="top"
          >
            <div
              class="project-img"
              @mouseover="handleMouseOver"
              @mouseleave="handleMouseLeave"
              style="position: relative"
            >
              <!-- <img
              style="position: absolute; top: 100px; left: -50px"
              v-show="isShow"
              :src="require('@/assets/home/handyServe/one.jpg')"
              alt="客服二维码"
            /> -->
              <img
                :src="require('@/assets/home/OnlineConsultation.png')"
                alt="在线咨询"
              />
              <!-- <img
              style="position: absolute; top: 100px; left: 50px"
              v-show="isShow"
              :src="require('@/assets/home/handyServe/two.jpg')"
              alt="客服二维码"
            /> -->
            </div>
          </el-tooltip>
          <p class="serve-name">在线咨询</p>
        </div>
        <div class="list-item" @click="toDetail('母婴服务')">
          <div class="project-img">
            <img
              :src="require('@/assets/home/MaternalAndChildService.png')"
              alt="母婴服务"
            />
          </div>
          <p class="serve-name">母婴服务</p>
        </div>
        <div class="list-item" @click="toDetail('交通指南')">
          <div class="project-img">
            <img :src="require('@/assets/home/traffic.png')" alt="交通指南" />
          </div>
          <p class="serve-name">交通指南</p>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import { onMounted, ref, defineEmits } from "vue";
import { api } from "@/api/handyServe/index.js";
import { useRouter } from "vue-router";

const router = useRouter();

const list = ref([]);

const toDetail = (title) => {
  list.value.forEach((element) => {
    if (element.title === title) {
      router.push({
        name: "handyServe",
        query: {
          id: element.id,
          title,
        },
      });
      return;
    }
  });
};

const emit = defineEmits(["goToEnd"]);
const toEnd = () => {
  // 触发父组件的 goToEnd 方法
  emit("goToEnd");
};

const isShow = ref(false);
const handleMouseOver = () => {
  isShow.value = true;
};
const handleMouseLeave = () => {
  isShow.value = false;
};

onMounted(() => {
  api()
    .getList()
    .then((res) => {
      list.value = res.data;
    });
});
</script>

<style lang="scss" scoped>
.server-container {
  margin-top: 40px;
  width: 100%;
  height: 277px;
  background-color: rgb(229, 247, 247);
  border: 1;
  .divider {
    width: 100%;
    height: 44px;
    background-color: rgb(229, 247, 247);
    font-size: 20px;
    color: #787676;
    text-align: center;
    margin: 28px 0 0;
    line-height: 44px;
    font-weight: bold;
    letter-spacing: 9px;
  }

  .serve-list {
    width: 1424px;
    height: 187px;
    margin: 22px auto 0;
    display: flex;
    justify-content: space-around;

    .list-item {
      cursor: pointer;
      width: 94px;
      flex-shrink: 0;
      margin: 0 20px;
      .project-img {
        width: 100%;
        height: 94px;

        border-radius: 13px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 13px;
        }
      }

      .serve-name {
        margin-top: 15px;
        color: #787676;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        font-weight: bold;
      }
    }
  }
}
</style>
