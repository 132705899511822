<template>
  <div class="specialProject">
    <Header />
    <HeaderNav :navName="navName" />
    <div class="top-title">
      <div>
        <div class="top-title-text">特色项目</div>
        <div class="top-line"></div>
      </div>
    </div>
    <div class="main">
      <div class="left">
        <div class="left-title">{{ info.title }}</div>
        <div class="left-time">发布时间：{{ info.time }}</div>
        <div class="left-line"></div>
        <div class="left-text">
          <WangEditor v-model="info.content" />
        </div>
        <div
          class="left-related-info"
          style="
            margin-top: 40px;

            font-weight: bold;
            font-size: 30px;
            color: #828282;
          "
        >
          相关信息
        </div>
        <div
          class="left-related-line"
          style="width: 50px; height: 3px; background: #00b2b3"
        ></div>
        <div class="left-line"></div>
        <div
          class="left-related-text"
          v-if="infoR.id !== ''"
          @click="goToDetail(infoR.id)"
        >
          <div class="image">
            <el-image style="width: 100%; height: 100%" :src="infoR.bpic" lazy>
            </el-image>
          </div>
          <div class="text">
            <div v-if="infoR.title" class="text-title">
              {{ infoR.title }}
            </div>
            <div v-if="infoR.subTitle" class="text-content">
              {{ infoR.subTitle }}
            </div>
            <div class="text-time">发布时间：{{ infoR.time }}</div>
          </div>
        </div>
        <div
          class="left-dashed-line"
          style="
            border: 1px dashed #e0e0e0;
            width: 100%;
            height: 2px;
            margin-bottom: 40px;
          "
        ></div>
      </div>
      <div class="right">
        <div class="right-all-title">相关活动</div>
        <div
          class="left-related-line"
          style="width: 50px; height: 3px; background: #00b2b3"
        ></div>
        <div class="right-line"></div>
        <div class="right-title">
          <el-scrollbar style="height: 80vh">
            <div
              style="cursor: pointer"
              v-for="item in infoAll"
              :key="item.id"
              @click="goToDetail(item.id)"
            >
              <div style="display: flex; align-items: center">
                <div class="image">
                  <el-image style="width: 118px" :src="item.url" lazy>
                  </el-image>
                </div>
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <aboutUs />
  </div>
</template>

<script setup>
import Header from "@/views/component/Header.vue";
import HeaderNav from "@/views/component/HeaderNav.vue";
import aboutUs from "@/views/home/aboutUs.vue";
import WangEditor from "@/views/component/editor/index.vue";

import { toRefs, onMounted, ref, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { api } from "@/api/specialProject/index.js";

// 使用 useRoute 获取参数
const route = useRoute();
const router = useRouter();

// 页面路由名称
const navName = ref("specialProject");

// 页面数据
const info = reactive({
  title: "",
  time: "",
  content: "",
  news: [],
});

// 相关数据
const infoR = reactive({
  id: "",
  title: "",
  subTitle: "",
  time: "",
  bpic: "",
});

// 全部数据
const infoAll = ref([]);

const getData = (id) => {
  api()
    .getDetail(id)
    .then((res) => {
      info.title = res.data.title;
      info.time = res.data.createTime;
      if (res.data.details !== null) {
        info.content = res.data.details;
      } else {
        info.content = "暂无内容";
      }
    });

  api()
    .getAll(id)
    .then((res) => {
      infoAll.value = res.data;
    });

  api()
    .getOther(id)
    .then((res) => {
      infoR.id = res.data.id;
      infoR.title = res.data.title;
      infoR.subTitle = res.data.subTitle;
      infoR.time = res.data.updateTime;
      infoR.bpic = res.data.url;
    });

  window.scrollTo(0, 0);
};

onMounted(() => {
  // 解构出来的不是响应式，需要用toRefs使其变成响应式
  let { query } = toRefs(route);
  getData(query.value.id);
});

// 更换新闻详情
const goToDetail = (id) => {
  getData(id);
};
</script>

<style lang="scss" scoped>
.specialProject {
  .top-title {
    width: 100%;
    height: 300px;
    background: #00b2b3;
    background-image: url("../../assets/detail/news/news.jpg");

    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    // height: 100vh; /* 设置高度为视口高度，可以根据需要调整 */
    text-align: center; /* 文本居中 */
    .top-title-text {
      display: inline-block;

      font-weight: 800;
      font-size: 34px;
      color: #f9f9f9;
    }
    .top-line {
      margin-top: 9px;
      margin-left: 43px;
      width: 50px;
      height: 4px;
      background: #ffffff;
    }
  }

  .top-topic {
    padding-left: 13.49%;
    background: #fbfbfb;
    .top-topic-text {
      display: inline-block;
      // width: 95px;
      // height: 21px;

      font-weight: 400;
      font-size: 22px;
      color: #828282;
      // line-height: 65px;
      margin-top: 29px;
      margin-bottom: 20px;
    }
    .top-line {
      width: 100px;
      height: 3px;
      background: #00b2b3;
    }
  }

  .main {
    width: 100%;
    padding-left: 13.13%;
    padding-right: 12.55%;
    display: flex;
    .left {
      width: 59.57%;
      margin-right: 5.39%;

      .left-title {
        font-weight: bold;
        font-size: 30px;
        color: #333333;
        margin-top: 43px;
      }

      .left-time {
        font-weight: 400;
        font-size: 16px;
        color: #696969;
        margin-top: 23px;
        margin-bottom: 23px;
      }

      .left-line {
        width: 100%;
        height: 1px;
        background: #828282;
      }

      .left-image {
        width: 628px;
        height: 291px;
        background: #e0e0e0;
        margin-top: 53px;
        margin-left: 12%;
      }

      .left-text {
        margin-top: 10px;
        .left-text-title {
          color: #838383;
          font-size: 18px;
          font-weight: 600;
        }
        .left-text-content {
          color: #838383;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 20px;
          line-height: 35px;
        }
      }

      .left-related-text {
        margin-top: 13px;
        margin-bottom: 14px;
        display: flex;
        cursor: pointer;

        .image {
          width: 260px;
          height: 180px;
          background: #e0e0e0;
        }
        .text {
          margin-top: 26px;
          margin-left: 12px;
          .text-title {
            font-weight: bold;
            font-size: 20px;
            color: #828282;
          }
          .text-content {
            font-weight: 400;
            font-size: 16px;
            color: #828282;
            margin-top: 22px;
            line-height: 30px;
          }
          .text-time {
            font-weight: 400;
            font-size: 16px;
            color: #696969;
            margin-top: 13px;
          }
        }
      }
    }

    .right {
      width: 35.04%;
      height: 21px;
      .right-all-title {
        font-weight: 400;
        font-size: 30px;
        color: #828282;
        padding-top: 40px;
        padding-bottom: 15px;
      }
      .right-line {
        width: 100%;
        height: 1px;
        background: #e0e0e0;
      }
      .right-title {
        .title {
          margin-left: 16px;

          font-weight: 400;
          font-size: 18px;
          color: #828282;

          // 限制两行文字
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
