<template>
  <div class="project-container">
    <div class="divider">特色项目</div>
    <div class="project">
      <!-- <div v-for="item in data" :key="item.id" class="project-item">
        <div class="div1">
          <img style="width: 476px; height: 302px" :src="item.url" alt="" />
        </div>
        <div class="div2">
          <h3>{{ item.title }}</h3>
        </div>
        <div class="div3">
          <p>{{ item.content }}</p>
        </div>
      </div> -->
      <swiper
        :modules="modules"
        :navigation="true"
        :slidesPerView="3"
        :spaceBetween="0"
        :loop="true"
        :autoplay="{
          delay: 4000,
          disableOnInteraction: false,
        }"
      >
        <swiper-slide
          style="cursor: pointer"
          v-for="(item, index) in data"
          :key="item.id"
          @click="toDetail(item.id)"
        >
          <div v-if="index % 2 === 0" class="project-item">
            <div class="img1">
              <img
                style="width: 475px; height: 300px"
                :src="item.url"
                alt="中医疗法"
              />
            </div>
            <div class="tra1">
              <h3>{{ item.title }}</h3>
              <p>{{ item.content }}</p>
            </div>
          </div>
          <div v-if="index % 2 !== 0" class="project-item">
            <div class="tra2">
              <h3>{{ item.title }}</h3>
              <p>{{ item.content }}</p>
            </div>
            <div class="img2">
              <img
                style="width: 475px; height: 314px"
                :src="item.url"
                alt="中医疗法"
              />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import { ref, onMounted } from "vue";
import { api } from "@/api/specialProject/index.js";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { useRouter } from "vue-router";

const router = useRouter();

const modules = ref([Pagination, Navigation, Autoplay]);

const data = ref([]);

onMounted(() => {
  api()
    .getList()
    .then((res) => {
      data.value = res.data;
    });
});

const toDetail = (id) => {
  router.push({
    name: "specialProject",
    query: {
      id,
    },
  });
};
</script>

<style lang="scss" scoped>
.project-container {
  width: 100%;
  height: 695px;
  background: rgb(229, 247, 247);
  .divider {
    width: 100%;
    height: 80px;
    background-color: rgb(229, 247, 247);
    font-size: 20px;
    color: #787676;
    text-align: center;
    line-height: 80px;
    font-weight: bold;
    letter-spacing: 9px;
  }
  .project {
    display: flex; // 弹性布局
    width: 1426px;
    height: 616px;
    margin: 0 auto;
    background-color: #fff;
    border-right: #5da2e0 1px dashed;
    // overflow-x: auto; /* 添加水平滚动条 */
    // overflow-y: hidden; /* 隐藏垂直滚动条 */
    .project-item {
      display: inline-block;
      width: 475px;
      height: 616px;
      background-color: #fff;
      border-top: #5da2e0 1px dashed;
      border-left: #5da2e0 1px dashed;
      border-bottom: #5da2e0 1px dashed;
      .img1 {
        width: 475px;
        height: 300px;
        opacity: 1;
      }
      .img2 {
        width: 475px;
        height: 314px;
        opacity: 1;
      }
      .img1:hover,
      .img2:hover {
        animation: flash 3s cubic-bezier(0.5, 0.8, 0.5, 1);
      }
      @keyframes flash {
        0%,
        100% {
          opacity: 1;
        }
        10% {
          opacity: 0.1;
        }
        50% {
          opacity: 0.5;
        }
      }
      .tra1 {
        height: 316px;
        transition: background-color 0.3s ease;
        h3 {
          font-size: 26px;
          color: #777576;
          padding-top: 20px;
          padding-bottom: 15px;
          text-align: center; /* 字体居中 */
        }
        p {
          height: 247px;
          font-size: 20px;
          color: #959595;
          line-height: 30px;
          margin: 0 20px;
          text-overflow: ellipsis; // 使用省略号表示被截断的文本
          white-space: pre-wrap; //  连续的空格会被保留
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 8;
          overflow: hidden;
        }
      }
      .tra2 {
        height: 300px;
        transition: background-color 0.3s ease;
        h3 {
          font-size: 26px;
          color: #777576;
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: center; /* 字体居中 */
        }
        p {
          height: 239px;
          font-size: 20px;
          color: #959595;
          line-height: 30px;
          margin: 0 20px;
          text-overflow: ellipsis; // 使用省略号表示被截断的文本
          white-space: pre-wrap; //  连续的空格会被保留
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 8;
          overflow: hidden;
        }
      }
      .tra1:hover,
      .tra2:hover {
        background-color: #00b2b3; /* 悬浮时的背景色 */
        h3 {
          color: white;
        }
        p {
          color: white;
        }
      }
    }
    .swiper {
      height: 100%;
    }
    :deep(.swiper-button-prev) {
      color: #00b2b3;
    }
    :deep(.swiper-button-next) {
      color: #00b2b3;
    }
  }
}
</style>
