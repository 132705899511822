<template>
  <div class="department-container" v-if="list">
    <div class="depart-img">
      <img style="width: 100%; height: 100%" :src="img" alt="胡氏儿科" />
    </div>
    <div class="msg-list">
      <div class="list-item" v-for="itme in list" :key="itme.id">
        <p class="header">{{ itme.title }}</p>
        <div class="content">
          <p>{{ itme.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { api } from "@/api/huShiChildDepart/index.js";

const list = ref([]);
const img = ref("");
onMounted(() => {
  api()
    .getList()
    .then((res) => {
      if (Array.isArray(res.data) && res.data.length !== 0) {
        list.value = res.data;
      }
    });
  api()
    .getImg()
    .then((res) => {
      if (res.data !== null && res.data !== "") {
        img.value = res.data.url;
      }
    });
});
</script>

<style lang="scss" scoped>
.department-container {
  width: 1424px;
  margin: 42px auto;
  display: flex;
  .depart-img {
    flex-basis: 644px;
    height: 748px;
  }

  .msg-list {
    margin-left: 70px;
    flex: 1;
    height: 748px;
    background-color: #00b2b3;
    padding: 0 12px;
    overflow: auto;
    .list-item {
      justify-content: space-between;
      flex-direction: column;
      display: flex;
      min-height: 70px;
      max-height: 33%;
      padding-bottom: 10px;
      border-bottom: 1px solid #d6d6d6;
      .header {
        font-size: 30px;
        font-weight: bold;
        margin-top: 12.5px;
        color: #ffffff;
        margin-bottom: 2.5px;
      }
      .content {
        flex: 1;
        overflow: scroll; /* 或者使用 overflow: scroll; */
        /* 隐藏滚动条 */
        &::-webkit-scrollbar {
          display: none;
        }
        p {
          font-size: 18px;
          color: white;
          text-indent: 0em;
          line-height: 30px;
          white-space: pre-wrap;
        }
      }
    }
  }
}
</style>
