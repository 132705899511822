<template>
  <div class="hospital-box" v-if="result">
    <div
      class="index-img"
      :style="{ backgroundImage: 'url(' + result.img + ')' }"
    ></div>

    <div class="introduce-content">
      <p>
        {{ result.intro }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { hospitalIntroduceApi } from "@/api/HospitalIntroduce/hospitalIntroduce.js";

const result = ref({});

//  获取数据
onMounted(() => {
  hospitalIntroduceApi()
    .get()
    .then((res) => {
      result.value = res.data;
    })
    .catch((error) => {
      console.error(error);
    });
});
</script>

<style lang="scss" scoped>
.hospital-box {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 390px;
  background: #00b2b3;
  padding: 10px;
  display: flex;
  .index-img {
    width: 553px;
    height: 368px;
    // background: url("@/assets/home/hospital.png") no-repeat;
    background-size: 100% 100%;
  }

  .introduce-content {
    flex: 1;
    margin-left: 30px;
    overflow: auto; /* 或者使用 overflow: scroll; */
    /* 隐藏滚动条 */
    &::-webkit-scrollbar {
      display: none;
    }
    /* 当鼠标悬停时显示滚动条 */
    // &:hover::-webkit-scrollbar {
    //   display: block;
    // }
    p {
      font-size: 18px;
      color: white;
      text-indent: 0em;
      line-height: 31px;
      // margin-top: 8px;
      white-space: pre-wrap;
    }
  }
}
</style>
