<template>
  <div class="doctor">
    <Header />
    <HeaderNav :navName="navName" />
    <div class="top-title">
      <div style="position: absolute; bottom: 12%; right: 12.6%">
        <div class="top-title-text">医生团队</div>
        <div class="top-line"></div>
      </div>
    </div>
    <div class="top-topic">
      <div class="top-topic-text">
        医生团队
        <span
          style="
            margin-left: 2px;
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #828282;
            margin-left: 2px;
            vertical-align: middle;
          "
        >
        </span>
      </div>
      <div class="top-line"></div>
    </div>
    <div class="info">
      <div class="image">
        <img :src="info.img" :alt="info.name" />
      </div>
      <div class="introduce">
        <div class="name">{{ info.name }} {{ info.title }}</div>
        <div class="introduce-info" v-html="info.introduce"></div>
        <div class="good">擅长：{{ info.good }}</div>
      </div>
      <div class="code">
        <img style="width: 128px" :src="info.qrCode" alt="二维码" />
        <div
          style="
            font-weight: 400;
            font-size: 15px;
            color: #00b2b3;
            line-height: 30px;
          "
        >
          微信扫码预约挂号
        </div>
      </div>
    </div>
    <div class="intr">
      <div class="intr-text">医生介绍</div>
      <div
        style="
          width: 59px;
          height: 4px;
          background: #00b2b3;
          margin-left: 13.4%;
        "
      ></div>
      <div class="left-line"></div>
      <div class="introduceText" v-html="info.introduceText"></div>
    </div>
    <div class="schedule">
      <div class="schedule-text">
        <div class="schedule-text1">门诊排班</div>
        <div class="schedule-text2">如有临时变动，请以门诊当时时间为准</div>
      </div>
      <div
        style="
          width: 59px;
          height: 4px;
          background: #00b2b3;
          margin-left: 13.4%;
        "
      ></div>
      <div class="left-line"></div>
      <div class="schedule-content">
        <table class="schedule-table">
          <thead>
            <tr>
              <th>时间</th>
              <th v-for="(day, index) in weekDays" :key="index">{{ day }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(timeSlot, timeIndex) in timeSlots" :key="timeIndex">
              <td class="bg-gray-50">{{ timeSlot === 0 ? "上午" : "下午" }}</td>
              <td v-for="(day, dayIndex) in 7" :key="dayIndex">
                <div
                  class="schedule-cell"
                  v-if="info.schedule[timeSlot][dayIndex].name"
                >
                  <div class="doctor-info">
                    <span class="text-primary">
                      {{ info.schedule[timeSlot][dayIndex].name }}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="other">
      <div class="other-text">其他医生</div>
      <div
        style="
          width: 59px;
          height: 4px;
          background: #00b2b3;
          margin-left: 13.4%;
        "
      ></div>
      <div class="left-line"></div>
      <div class="doctor-list">
        <!-- 医生卡片 -->
        <div
          v-for="doctor in info.doctors"
          :key="doctor.id"
          class="doctor-item"
          @click="goToDoctor(doctor.id)"
        >
          <div class="doctor-img">
            <img :src="doctor.img" :alt="doctor.name" style="width: 100%" />
          </div>
          <div class="doctor-info">
            <div class="doctor-name">
              {{ doctor.name }}
            </div>
            <div class="doctor-title">{{ doctor.type }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="related-info">
      <div
        style="
          margin-top: 40px;

          font-weight: bold;
          font-size: 30px;
          margin-left: 13.4%;
          margin-bottom: 15px;
          color: #828282;
        "
      >
        相关信息
      </div>
      <div
        style="
          width: 59px;
          height: 4px;
          background: #00b2b3;
          margin-left: 13.4%;
        "
      ></div>
      <div class="left-line"></div>
      <div
        class="related-text"
        v-if="infoR.id !== ''"
        @click="toDetail(infoR.id)"
      >
        <div class="image">
          <el-image style="width: 100%; height: 100%" :src="infoR.bpic" lazy>
          </el-image>
        </div>
        <div class="text">
          <div class="text-title">
            {{ infoR.title }}
          </div>
          <div class="text-content">
            {{ infoR.subTitle }}
          </div>
          <div class="text-time">发布时间：{{ infoR.time }}</div>
        </div>
      </div>
    </div>
    <aboutUs />
  </div>
</template>

<script setup>
import Header from "@/views/component/Header.vue";
import HeaderNav from "@/views/component/HeaderNav.vue";
import aboutUs from "@/views/home/aboutUs.vue";

import { toRefs, onMounted, ref, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { newsApi } from "@/api/news/news.js";
import { specialistApi } from "@/api/doctor/doctor.js";

// 使用 useRoute 获取参数
const route = useRoute();
// 页面路由跳转
const router = useRouter();

// 页面路由名称
const navName = ref("doctor");

// 日期
const weekDays = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
const timeSlots = [0, 1];

// 页面数据
const info = reactive({
  id: 0,
  img: "../../assets/detail/doctor/医生团队-张爷爷.jpg",
  name: "",
  title: "教授",
  introduce:
    "首届全国名中医/首届四川川省名中医/享受国务院特殊津贴专家<br>博士生导师/全国老中医药专家学术经验继承工作指导老师<br>获四川川省卫生计生领域终身成就奖/美国俄勒冈东方医学院客座教授、荣誉博士",
  good: "糖尿病及其并发症、肾病综合征、高血压；不孕不育、更年期综合征、性功能障碍、座疮、瘙痒；甲状腺疾病：甲亢、甲减、甲状腺结节等；颈、肩、腰、背疼痛，痛风、中风；失眠、健忘、老年痴呆、高热及其他疑难杂症。",
  qrCode: "",
  introduceText:
    "成都中医药大学教授、博士生导师、首届全国名中医、首届四川省名中医、全国名老中医药专家学术经验继承指导教师、享受国务院政府特殊津贴专家，被授予四川省劳动模范、四川省卫生计生领域终身成就奖、中华中医药学会发展成就奖。<br>从事中医临床、教学和科研工作60余载，担任成都中医药大学附属医院大内科主任20余年，对温热病、肾病、脑血管病、糖尿病等进行了深入系统的研究，成绩卓著，是我国中医内科学的领军人物。1990年创建省中医药学会糖尿病专委会并担任主任委员，主编出版《中西医结合糖尿病治疗学》，参与制定《糖尿病及其并发症中医辨证标准》作为行业标准在全国推广，是全国最早的中医糖尿病专家、中医内分泌学科的奠基人之一。<br>60余年来带教1000余名学生，培养博士生47名、学术继承人6名、中医优才20余名。编撰论著10余部，获部省级科技进步奖15项。其学术思想及成果已推广至全国影响深远。多次应邀去海外学术交流。2004年受聘担任美国俄勒冈东方医学院客座教授2010年被授予荣誉博士学位。",
  schedule: [
    [{}, { name: "坐诊" }, {}, {}, { name: "坐诊" }, {}, { name: "坐诊" }],
    [{ name: "坐诊" }, {}, {}, {}, {}, {}, { name: "坐诊" }],
  ],
  doctors: [
    {
      id: 1,
      name: "徐明远",
      type: "主任医师 / 博士生导师",
      img: "https://ai-public.mastergo.com/ai/img_res/1fabca49d3c99f3bc5a4c9c162a49e7a.jpg",
    },
    {
      id: 2,
      name: "陈雅琪",
      type: "副主任医师 / 硕士生导师",
      img: "https://ai-public.mastergo.com/ai/img_res/b3fdcc7244510d833d20af4c6f5af385.jpg",
    },
    {
      id: 3,
      name: "赵建国",
      type: "主任医师 / 省级名医",
      img: "https://ai-public.mastergo.com/ai/img_res/ce17574638440f38a3302519a07a623f.jpg",
    },
    {
      id: 4,
      name: "林思颖",
      type: "副主任医师 / 博士",
      img: "https://ai-public.mastergo.com/ai/img_res/36776a16787ce69c594f19a54e7b8156.jpg",
    },
    {
      id: 5,
      name: "林思颖",
      type: "副主任医师 / 博士",
      img: "https://ai-public.mastergo.com/ai/img_res/36776a16787ce69c594f19a54e7b8156.jpg",
    },
  ],
});

// 相关数据
const infoR = reactive({
  id: "",
  title: "",
  subTitle: "",
  time: "",
  bpic: "",
});

const toDetail = (id) => {
  router.push({
    name: "news",
    query: {
      id,
    },
  });
};

// 排班数据
const schedules = [
  [
    [{}, {}, {}, {}, {}, {}, {}],
    [{}, { name: "坐诊" }, {}, {}, { name: "坐诊" }, {}, {}],
  ],
  [
    [{}, {}, {}, {}, {}, {}, {}],
    [{}, { name: "坐诊" }, {}, {}, { name: "坐诊" }, {}, {}],
  ],
  [
    [{}, { name: "坐诊" }, {}, {}, { name: "坐诊" }, {}, {}],
    [{}, {}, {}, {}, {}, {}, {}],
  ],
  [
    [{}, {}, {}, {}, { name: "坐诊" }, {}, { name: "坐诊" }],
    [{}, {}, {}, {}, {}, {}, {}],
  ],
  [
    [{}, {}, {}, { name: "坐诊" }, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}, {}],
  ],
  [
    [
      {},
      { name: "坐诊" },
      {},
      {},
      { name: "坐诊" },
      { name: "坐诊" },
      { name: "坐诊" },
    ],
    [
      { name: "坐诊" },
      { name: "坐诊" },
      {},
      { name: "坐诊" },
      { name: "坐诊" },
      {},
      {},
    ],
  ],
  [
    [{}, {}, {}, {}, {}, { name: "坐诊" }, {}],
    [{ name: "坐诊" }, {}, {}, {}, {}, {}, {}],
  ],
  [
    [{}, {}, {}, { name: "坐诊" }, {}, {}, { name: "坐诊" }],
    [{}, {}, {}, {}, {}, {}, {}],
  ],
  [
    [{}, {}, {}, { name: "坐诊" }, {}, {}, {}],
    [{}, { name: "坐诊" }, {}, {}, {}, {}, {}],
  ],
  [
    [{}, {}, {}, {}, {}, {}, { name: "坐诊" }],
    [{}, {}, { name: "坐诊" }, {}, {}, {}, {}],
  ],
  [
    [{}, {}, {}, {}, {}, { name: "坐诊" }, { name: "坐诊" }],
    [{}, {}, {}, {}, {}, {}, {}],
  ],
  [
    [{}, {}, {}, {}, {}, {}, { name: "坐诊" }],
    [
      {},
      { name: "坐诊" },
      { name: "坐诊" },
      {},
      { name: "坐诊" },
      { name: "坐诊" },
      {},
    ],
  ],
  [
    [{}, {}, {}, {}, {}, { name: "坐诊" }, {}],
    [{}, {}, {}, {}, {}, {}, {}],
  ],
  [
    [{}, {}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, { name: "坐诊" }, {}, { name: "坐诊" }],
  ],
];

// 获取医生信息
const getDoctor = (id) => {
  specialistApi()
    .getDetail(id)
    .then((res) => {
      info.img = res.data.img;
      info.name = res.data.name;
      info.title = res.data.type;
      info.introduce = res.data.detail;
      info.good = res.data.goodAt;
      info.qrCode = res.data.qrCode;
      info.introduceText = res.data.intro;
      info.doctors = res.data.otherDoctors;

      if (info.name === "张发荣") {
        info.schedule = schedules[0];
      } else if (info.name === "廖方正") {
        info.schedule = schedules[1];
      } else if (info.name === "周紫娟") {
        info.schedule = schedules[2];
      } else if (info.name === "李朝敏") {
        info.schedule = schedules[3];
      } else if (info.name === "傅晓辉") {
        info.schedule = schedules[4];
      } else if (info.name === "胡波") {
        info.schedule = schedules[5];
      } else if (info.name === "周江") {
        info.schedule = schedules[6];
      } else if (info.name === "马小兰") {
        info.schedule = schedules[7];
      } else if (info.name === "邓雪梅") {
        info.schedule = schedules[8];
      } else if (info.name === "卿金星") {
        info.schedule = schedules[9];
      } else if (info.name === "李松桃") {
        info.schedule = schedules[10];
      } else if (info.name === "张森") {
        info.schedule = schedules[11];
      } else if (info.name === "韩瑞阳") {
        info.schedule = schedules[12];
      } else if (info.name === "丁培文") {
        info.schedule = schedules[13];
      }
    });
};

const goToDoctor = (id) => {
  console.log(id, "刷新医生信息");
  getDoctor(id);
  // 跳转后滚动到页面顶部
  window.scrollTo(0, 0);
};

onMounted(() => {
  // 解构出来的不是响应式，需要用toRefs使其变成响应式
  let { query } = toRefs(route);
  getDoctor(query.value.id);
  newsApi()
    .getR(0)
    .then((res) => {
      infoR.id = res.data.id;
      infoR.title = res.data.title;
      infoR.subTitle = res.data.subTitle;
      infoR.time = res.data.updateTime;
      infoR.bpic = res.data.bpic;
    });
  // 跳转后滚动到页面顶部
  window.scrollTo(0, 0);
});
</script>

<style lang="scss" scoped>
.doctor {
  .top-title {
    width: 100%;
    height: 450px;
    background: #00b2b3;
    background-image: url("../../assets/detail/doctor/doctor.jpg");

    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    // height: 100vh; /* 设置高度为视口高度，可以根据需要调整 */
    text-align: center; /* 文本居中 */
    position: relative;

    .top-title-text {
      display: inline-block;

      font-weight: 800;
      font-size: 34px;
      color: #f9f9f9;
    }
    .top-line {
      margin-top: 9px;
      width: 50px;
      height: 4px;
      background: #ffffff;
    }
  }

  .top-topic {
    padding-left: 13.49%;
    background: #fbfbfb;
    .top-topic-text {
      display: inline-block;

      font-weight: 400;
      font-size: 22px;
      color: #828282;
      margin-top: 29px;
      margin-bottom: 20px;
    }
    .top-line {
      width: 100px;
      height: 3px;
      background: #00b2b3;
    }
  }

  .info {
    margin-top: 58px;
    display: flex;
    .image {
      width: 8.282%;
      margin-left: 13.4%;
    }
    .introduce {
      width: 55.78125%;
      margin-left: 29px;
      .name {
        font-weight: bold;
        font-size: 22px;
        color: #00b2b3;
      }
      .introduce-info {
        font-weight: 400;
        font-size: 17px;
        color: #00b2b3;
        line-height: 30px;
        margin-top: 10px;
      }
      .good {
        font-weight: 400;
        font-size: 16px;
        color: #828282;
        line-height: 30px;
        margin-top: 18px;
      }
    }
    .code {
      margin-top: 30px;
      margin-left: 24px;
    }
  }

  .intr {
    margin-top: 30px;
    background-color: #f8f8f8;
    .intr-text {
      font-weight: bold;
      font-size: 30px;
      color: #7a7a7a;
      line-height: 65px;
      margin-left: 13.4%;
    }

    .introduceText {
      margin-left: 13.4%;
      margin-top: 22px;
      padding-bottom: 25px;
      width: 73.59375%;

      font-weight: 400;
      font-size: 15px;
      color: #333333;
      line-height: 25px;
    }
  }

  .left-line {
    width: 73.59375%;
    height: 1px;
    background: #828282;
    margin-left: 13.4%;
  }

  .schedule {
    margin-top: 30px;
    .schedule-text {
      margin-left: 13.4%;
      display: flex;
      align-items: flex-end;
      margin-bottom: 13px;

      .schedule-text1 {
        font-weight: bold;
        font-size: 30px;
        color: #7a7a7a;
      }
      .schedule-text2 {
        margin-left: 10px;

        font-weight: 400;
        font-size: 18px;
        color: #7a7a7a;
      }
    }

    .schedule-content {
      margin-left: 13.4%;
      margin-top: 33px;
      .schedule-table {
        border-collapse: collapse;
      }
      // width: 73.59375%;
      .schedule-table th,
      .schedule-table td {
        border: 1px solid #e5e7eb;
        padding: 16px;
        text-align: center;
        width: calc(9.1vw); /* 减去 padding 的总宽度 */
      }

      .schedule-table th {
        background-color: #f9fafb;
        font-weight: 500;
        color: #797979;
        font-size: 18px;
      }

      .schedule-table td {
        height: 60px;
        vertical-align: middle;
        color: #797979;
        font-size: 15px;
      }
    }
  }

  .other {
    .other-text {
      font-weight: bold;
      font-size: 30px;
      color: #7a7a7a;
      line-height: 65px;
      margin-left: 13.4%;
      margin-top: 10px;
    }

    .doctor-list {
      display: flex;
      margin-left: 13.4%;
      margin-top: 30px;
      .doctor-item {
        border: 1px solid #e0e0e0;
        margin-right: 20px;
        .doctor-img {
          width: calc(13.65vw);
          padding: 10px;
        }
        .doctor-info {
          .doctor-name {
            font-weight: bold;
            font-size: 25px;
            color: #00b2b3;
            text-align: center;
          }
          .doctor-title {
            font-weight: 400;
            font-size: 15px;
            color: #7f7c7c;
            line-height: 30px;
            text-align: center;
          }
        }
      }
    }
  }

  .related-info {
    .related-text {
      margin-left: 13.4%;
      margin-top: 13px;
      margin-bottom: 30px;
      display: flex;
      cursor: pointer;

      .image {
        width: 260px;
        height: 180px;
        background: #e0e0e0;
      }
      .text {
        margin-top: 26px;
        margin-left: 12px;
        .text-title {
          font-weight: bold;
          font-size: 20px;
          color: #828282;
        }
        .text-content {
          font-weight: 400;
          font-size: 16px;
          color: #828282;
          margin-top: 22px;
          line-height: 30px;
        }
        .text-time {
          font-weight: 400;
          font-size: 16px;
          color: #696969;
          margin-top: 13px;
        }
      }
    }
  }
}
</style>
