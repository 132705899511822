<template>
  <div class="partyBuild">
    <Header />
    <HeaderNav :navName="navName" />
    <div class="top-title">
      <div>
        <div class="top-title-text">{{ info.title }}</div>
        <div class="top-line"></div>
      </div>
    </div>
    <div class="main">
      <div class="left-title">{{ info.title }}</div>
      <div class="left-line"></div>
      <div class="left-text">
        <WangEditor v-model="info.content" />
      </div>
    </div>
    <aboutUs />
  </div>
</template>

<script setup>
import Header from "@/views/component/Header.vue";
import HeaderNav from "@/views/component/HeaderNav.vue";
import aboutUs from "@/views/home/aboutUs.vue";
import WangEditor from "@/views/component/editor/index.vue";

import { toRefs, onMounted, ref, reactive } from "vue";
import { useRoute } from "vue-router";
import { api } from "@/api/handyServe/index.js";

// 使用 useRoute 获取参数
const route = useRoute();

// 页面路由名称
const navName = ref("handyServe");

// 页面数据
const info = reactive({
  title: "暂无内容",
  content: "暂无内容",
});

onMounted(() => {
  // 解构出来的不是响应式，需要用toRefs使其变成响应式
  let { query } = toRefs(route);
  api()
    .getDetail(query.value.id)
    .then((res) => {
      info.title = res.data.title;
      if (res.data.content !== null) {
        info.content = res.data.content;
      } else {
        info.content = "暂无内容";
      }
    });

  window.scrollTo(0, 0);
});
</script>

<style lang="scss" scoped>
.partyBuild {
  .top-title {
    width: 100%;
    height: 300px;
    background: #00b2b3;
    background-image: url("../../assets/detail/news/news.jpg");

    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    // height: 100vh; /* 设置高度为视口高度，可以根据需要调整 */
    text-align: center; /* 文本居中 */
    .top-title-text {
      display: inline-block;

      font-weight: 800;
      font-size: 34px;
      color: #f9f9f9;
    }
    .top-line {
      margin-top: 9px;
      margin-left: 43px;
      width: 50px;
      height: 4px;
      background: #ffffff;
    }
  }

  .main {
    width: 100%;
    padding-left: 13.13%;
    padding-right: 12.55%;

    .left-title {
      font-weight: bold;
      font-size: 30px;
      color: #333333;
      margin-top: 43px;
    }

    .left-time {
      font-weight: 400;
      font-size: 16px;
      color: #696969;
      margin-top: 23px;
      margin-bottom: 23px;
    }

    .left-line {
      width: 100%;
      height: 1px;
      background: #828282;
    }

    .left-image {
      width: 628px;
      height: 291px;
      background: #e0e0e0;
      margin-top: 53px;
      margin-left: 12%;
    }

    .left-text {
      margin-top: 10px;
      .left-text-title {
        color: #838383;
        font-size: 18px;
        font-weight: 600;
      }
      .left-text-content {
        color: #838383;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
        line-height: 35px;
      }
    }

    .left-related-text {
      margin-top: 13px;
      margin-bottom: 14px;
      display: flex;
      cursor: pointer;

      .image {
        width: 260px;
        height: 180px;
        background: #e0e0e0;
      }
      .text {
        margin-top: 26px;
        margin-left: 12px;
        .text-title {
          font-weight: bold;
          font-size: 20px;
          color: #828282;
        }
        .text-content {
          font-weight: 400;
          font-size: 16px;
          color: #828282;
          margin-top: 22px;
          line-height: 30px;
        }
        .text-time {
          font-weight: 400;
          font-size: 16px;
          color: #696969;
          margin-top: 13px;
        }
      }
    }
  }
}
</style>
