<template>
  <Editor
    style="width: 100%; overflow-y: hidden"
    v-model="valueHtml"
    :defaultConfig="editorConfig"
  >
  </Editor>
</template>
<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { Editor } from "@wangeditor/editor-for-vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
});
const valueHtml = computed({
  get() {
    return props.modelValue;
  },
  set(value) {},
});
const editorConfig = { readOnly: true };
</script>
