<template>
  <div class="docter-container">
    <div class="inner-container">
      <div class="left-search">
        <p class="title">专家团队</p>

        <!-- <el-input
          v-model="form.department"
          style="width: 240px;margin-top: 36px"
          placeholder="请选择科室"
          disabled
        />
        <el-input
          v-model="form.title"
          style="width: 240px;margin-top: 36px"
          placeholder="请选择职称"
          disabled
        />-->

        <el-input
          v-model="form.keywords"
          style="width: 275px; height: 41px; margin-top: 90px; font-size: 18px"
          placeholder="根据病例搜索医生"
          :disabled="false"
        >
        </el-input>

        <el-button
          style="width: 275px; height: 41px; margin-top: 60px; font-size: 18px"
          @click="search"
        >
          查询医生
        </el-button>

        <img
          :src="require('@/assets/home/consult.jpg')"
          style="margin-top: 150px"
          alt="百安国医馆"
        />
      </div>
      <div class="doctor-container">
        <el-scrollbar style="height: 770px">
          <div class="doctor-list">
            <div
              v-for="item in doctorList"
              :key="item.id"
              class="list-item"
              @click="toDetail(item.id)"
            >
              <div class="tips"></div>
              <div class="doctor-name">{{ item.name }}</div>
              <div class="doctor-title">
                <span v-if="item.type === 'PROFESSOR'">教授</span>
                <span v-else-if="item.type === 'ARCHIATER'">主任医师</span>
                <span v-else-if="item.type === 'ARCHIATER_MIDDLE'">
                  主任中医师
                </span>
                <span v-else-if="item.type === 'DOCTOR'">博士</span>
                <span v-else-if="item.type === 'ASSOCIATE_CHIEF_PHYSICIAN'">
                  副主任医师
                </span>
                <span v-else-if="item.type === 'ACUPUNCTURIST'">针灸医师</span>
                <span v-else-if="item.type === 'VISITING_STAFF'">主治医师</span>
                <span v-else-if="item.type === 'PROFESSIONAL_PHYSICIAN'">
                  职业医师
                </span>
              </div>
              <div class="doctor-img">
                <img
                  style="width: 100%; height: 100%"
                  :src="item.img"
                  :alt="item.name"
                />
                <div
                  class="sancode"
                  :style="{
                    background: `url(${item.qrCode})`,
                    backgroundSize: '100% 100%',
                  }"
                ></div>
              </div>

              <div class="doctor-msg">
                <div v-html="item.intro"></div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { specialistApi } from "@/api/doctor/doctor.js";
import { useRouter } from "vue-router";

const doctorList = ref([]);

const router = useRouter();

//  获取数据
onMounted(() => {
  specialistApi()
    .getSpecialist()
    .then((res) => {
      res.data.forEach((item) => {
        item.intro = item.intro.replace(/\n/g, "<br>");
      });
      doctorList.value = res.data;
    })
    .catch((error) => {
      console.error(error);
    });
});

const form = reactive({
  department: "",
  title: "",
  keywords: "",
});

const search = () => {
  specialistApi()
    .searchByCase(form.keywords)
    .then((res) => {
      doctorList.value = res.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

const toDetail = (id) => {
  router.push({
    name: "doctor",
    query: {
      id,
    },
  });
};

const formattedMessage = computed(() => {
  // 将空格替换为换行
  return message.value.replace(/ /g, "<br>");
});
</script>

<style lang="scss" scoped>
.docter-container {
  height: 800px;
  background: #b2e8e8;
  .inner-container {
    width: 1424px;
    margin: 0 auto;
    height: 100%;
    display: flex;

    .left-search {
      flex-basis: 374.6px;
      height: 100%;
      width: 374.6px;
      padding: 0 25px;
      background: #00b2b3;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 40px;
        color: white;
        margin-top: 123px;
      }
    }
    .doctor-container {
      flex: 1;
      padding: 10px 25px 20px 25px;
      .doctor-list {
        width: 1016px;
        height: 780px;
        display: flex;
        flex-wrap: wrap;
        .list-item {
          width: 214px;
          height: 369.2px;
          background: white;
          position: relative;
          overflow: auto;
          margin: 10px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .tips {
            position: absolute;
            top: 0;
            width: 171px;
            height: 5.4px;
            left: 22px;
            background: #00b2b3;
          }

          .doctor-name {
            font-size: 20px;
            color: #00b2b3;
            margin-top: 18px;
            font-weight: bold;
            text-align: center;
          }
          .doctor-title {
            font-size: 15px;
            color: #787676;
            margin: 8px 0;
          }
          .doctor-img {
            position: relative;
            width: 172px;
            height: 201px;
            margin-bottom: 16px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
            .sancode {
              position: absolute;
              width: 120px;
              height: 120px;
              left: 50%;
              top: 50%;
              z-index: 9999;
              transform: translate(-50%, -50%);
              display: none;
            }
          }
          .doctor-img:hover .sancode {
            display: block;
          }

          .doctor-msg {
            font-size: 13.5px;
            color: #000000;
            text-align: center;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* 指定行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            width: 165px;
          }
        }
      }
    }
  }
}
</style>
