<template>
  <div>
    <div class="divider">特色专病</div>

    <div class="department-container">
      <div class="tab-title">
        <el-tabs type="card" v-model="activeName" @tab-change="tabChange">
          <el-tab-pane
            :key="p.id"
            v-for="p in projectList"
            :label="p.title"
            :name="p.url"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <!-- <div style="float: right">
        <span style="color: #787676; vertical-align: middle; font-size: 16px"
          >更多</span
        >
        <el-icon color="#787676" style="vertical-align: middle">
          <DArrowRight />
        </el-icon>
      </div> -->
      <p style="clear: both"></p>

      <div class="department-msg">
        <div class="first-left-msg">
          <swiper
            :modules="modules"
            :navigation="true"
            :loop="true"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
          >
            <swiper-slide>
              <img
                style="width: 100%; height: 100%"
                :src="activeName"
                alt="中医疗法"
              />
            </swiper-slide>
          </swiper>
        </div>

        <div class="msg-list">
          <div
            class="list-item"
            v-for="item in content"
            :key="item.subjectId"
            @click="goToDetail(item.subjectId)"
          >
            <div class="item-title-container">
              <p v-if="item.title" class="title">
                {{ item.title }}
              </p>
              <p class="update-time">{{ item.createTime }}</p>
            </div>
            <div class="item-img">
              <img
                :src="item.url"
                style="width: 100%; height: 100%"
                alt="中医疗法"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DArrowRight } from "@element-plus/icons-vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { ref, onMounted } from "vue";
import { characteristicDepartmentApi } from "@/api/CharacteristicDepartment/characteristicDepartment.js";
import { useRouter } from "vue-router";

const router = useRouter();

const modules = ref([Navigation, Autoplay]);
const activeName = ref("");
const projectList = ref([]);
const content = ref([]);
const tabChange = () => {
  projectList.value.some((element) => {
    if (element.url === activeName.value) {
      characteristicDepartmentApi()
        .getList(element.id)
        .then((res) => {
          content.value = res.data.subjectVOList;
        });
      return true; // 停止遍历
    }
    return false; // 继续遍历
  });
};

const goToDetail = (id) => {
  let nav = "";
  let projectId = "";
  projectList.value.some((element) => {
    if (element.url === activeName.value) {
      nav = element.title;
      projectId = element.id;
      return true; // 停止遍历
    }
    return false; // 继续遍历
  });
  router.push({
    name: "characteristic",
    query: {
      id,
      nav,
      projectId,
    },
  });
};

onMounted(() => {
  characteristicDepartmentApi()
    .get()
    .then((res) => {
      projectList.value = res.data;
      activeName.value = projectList.value[0].url;
      characteristicDepartmentApi()
        .getList(projectList.value[0].id)
        .then((res) => {
          content.value = res.data.subjectVOList;
        });
    });
});
</script>

<style lang="scss" scoped>
.divider {
  width: 100%;
  height: 44px;
  background-color: rgb(229, 247, 247);
  font-size: 20px;
  color: #787676;
  text-align: center;
  margin: 40px 0;
  line-height: 44px;
  letter-spacing: 9px;
  font-weight: bold;
}

.department-container {
  width: 1424px;
  margin: 0 auto;
  .tab-title {
    width: 1424px;
    float: left;
    margin-bottom: 10px;
    :deep(.el-tabs__item) {
      color: #787676;
      font-size: 18px;
    }
    :deep(.el-tabs__item.is-active, .el-tabs__item:hover) {
      color: white !important; /* 选中标签的字体颜色 */
      font-weight: bold;
      background-color: #00b2b3; /* 选中标签的背景颜色 */
    }
    :deep(.el-tabs__active-bar) {
      background: #00b2b3;
    }
  }

  .department-msg {
    display: flex;
    height: 516px;
    margin-bottom: 60px;
    .first-left-msg {
      width: 551px;
      height: 490px;
      .swiper {
        width: 100%;
        height: 100%;
      }
      :deep(.swiper-button-prev) {
        color: #00b2b3;
      }
      :deep(.swiper-button-next) {
        color: #00b2b3;
      }
    }

    .msg-list {
      margin-left: 20px;
      flex: 1;
      .list-item {
        cursor: pointer;
        margin-bottom: 35px;
        display: flex;
        justify-content: space-between;
        height: 140px;
        border-bottom: 3.3px solid #d6d6d6;
        .item-title-container {
          flex: 1;
          position: relative;
          .title {
            width: 600px;
            color: #787676;
            font-size: 26px;
            line-height: 50px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 指定行数 */
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .update-time {
            color: #787676;
            font-size: 20px;
            font-weight: 400;
            position: absolute;
            bottom: 10px;
          }
        }
        .item-img {
          width: 236px;
          height: 118px;
          background-color: #00b2b3;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
