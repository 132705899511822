<template>
  <div style="margin: 40px 0">
    <TitleHeader title="新闻动态" />
    <div class="news-content">
      <div class="first-new-content">
        <swiper
          :modules="modules"
          :navigation="true"
          :loop="true"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
        >
          <swiper-slide v-for="item in img" :key="item.id">
            <img
              :src="item.url"
              style="width: 100%; height: 100%"
              alt="百安国医馆"
            />
          </swiper-slide>
        </swiper>

        <div class="news-title">
          热烈庆祝百安国医馆挂牌成都市科青联青年医师专委会公益科普站点
        </div>
      </div>

      <div class="news-more">
        <el-scrollbar>
          <ul>
            <li
              v-for="item in titleList"
              :key="item.id"
              @click="toDetail(item.id)"
            >
              <el-icon color="#444444" size="14">
                <CaretRight />
              </el-icon>
              <span class="title">{{ item.title }}</span>
              <span class="time">{{ item.updateTime }}</span>
            </li>
          </ul>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { CaretRight } from "@element-plus/icons-vue";
import TitleHeader from "@/views/component/TitleHeader.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { newsApi } from "@/api/news/news.js";
import { useRouter } from "vue-router";

const modules = ref([Navigation, Autoplay]);
const banner = require("@/assets/home/news1.png");

let titleList = ref([]);
let img = ref([]);

const router = useRouter();

onMounted(() => {
  newsApi()
    .getNews()
    .then((res) => {
      titleList.value = res.data;
    })
    .catch((error) => {
      console.error(error);
    });
  newsApi()
    .getBanne()
    .then((res) => {
      img.value = res.data;
    })
    .catch((error) => {
      console.error(error);
    });
});

const toDetail = (id) => {
  router.push({
    name: "news",
    query: {
      id,
    },
  });
};
</script>

<style lang="scss" scoped>
.news-content {
  width: 100%;
  height: 345px;
  display: flex;
  .first-new-content {
    position: relative;
    flex-basis: 480px;
    height: 345px;
    .swiper {
      width: 644px;
      height: 345px;
    }

    :deep(.swiper-button-prev) {
      color: #00b2b3;
    }
    :deep(.swiper-button-next) {
      color: #00b2b3;
    }

    .news-title {
      font-size: 24px;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
      background: #444444;
      opacity: 0.8;
      color: white;
      line-height: 50px;
      z-index: 1000;
      padding: 0 20px;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .news-more {
    flex: 1;
    margin-left: 20px;
    overflow: auto; /* 或者使用 overflow: scroll; */
    ul {
      li {
        list-style: none;
        height: 39.5px;
        line-height: 39.5px;
        cursor: pointer; /* 将光标样式设置为指针，表示可点击 */

        .title {
          display: inline-block;
          width: 85%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 10px;
          font-size: 17px;
          color: #787676;
          vertical-align: middle;
        }

        .time {
          font-size: 17px;
          color: #787676;
          // margin-right: 1em;
        }
      }
    }
  }
}
</style>
