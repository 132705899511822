<template>
  <div class="nav">
    <div class="tab-container">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="goToHome">
        <el-tab-pane label="首页" name="home"></el-tab-pane>
        <el-tab-pane label="医馆简介" name="hospitalIntroduce"></el-tab-pane>
        <el-tab-pane label="新闻动态" name="news"></el-tab-pane>
        <el-tab-pane label="专家团队" name="doctor"></el-tab-pane>
        <el-tab-pane
          label="特色专病"
          name="characteristicDepartment"
        ></el-tab-pane>
        <el-tab-pane
          label="非物质文化遗产-胡氏儿科"
          name="hushiChild"
        ></el-tab-pane>
        <el-tab-pane label="特色项目" name="specialProject"></el-tab-pane>
        <el-tab-pane label="便民服务" name="handyServe"></el-tab-pane>
        <el-tab-pane label="名医讲堂" name="famousDoctor"></el-tab-pane>
        <el-tab-pane label="党建工作" name="partyBuild"></el-tab-pane>
        <!-- <el-tab-pane
            label="医馆环境"
            name="hospitalEnvironment"
          ></el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

// props参数
const props = defineProps(["navName"]);

// 页面导航路由显示
const activeName = ref(props.navName);

const router = useRouter();

const goToHome = (tab) => {
  const refName = tab.props.name;
  router.push({
    name: "home",
    query: {
      refName,
    },
  });
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 60px;
  margin-top: 10px;
  background-color: #00b2b3;
  .tab-container {
    width: 1400px;
    height: 100%;
    margin: 0 auto;
    :deep(.el-tabs__content) {
      display: none;
    }
    :deep(.el-tabs__header) {
      margin: 0;
    }
    :deep(.el-tabs__item) {
      height: 60px;
      color: white;
      padding: 0 29px !important; // 修改导航栏内容的左右内边距
      font-size: 18px;
      font-weight: bold;
    }
    :deep(.el-tabs__nav-wrap:after) {
      background: transparent;
    }
    :deep(.el-tabs__item.is-active, .el-tabs__item:hover) {
      color: #00b2b3 !important;
      background-color: white !important;
    }
    :deep(.el-tabs__active-bar) {
      background: #00b2b3;
    }
    :deep(.el-tabs__nav-next) {
      line-height: 60px;
      color: white;
    }
    :deep(.el-tabs__nav-prev) {
      line-height: 60px;
      color: white;
    }
  }
}
</style>
