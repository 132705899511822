import request from "@/utils/request";

/**
 * 特色专病接口
 *
 */
export function characteristicDepartmentApi() {
  return {
    get: () => {
      return request({
        url: "/website/specializedSubject/front/specialize/list",
        method: "get",
      });
    },
    getList: (id) => {
      return request({
        url:
          "/website/specializedSubject/front/specialize/detailSpecialize/" + id,
        method: "get",
      });
    },
    getD: (id) => {
      return request({
        url: "/website/specializedSubject/front/subjectDetail",
        method: "get",
        params: {
          id: id,
        },
      });
    },
    getOther: (id) => {
      return request({
        url: "/website/specializedSubject/front/randomNews",
        method: "get",
        params: {
          id: id,
        },
      });
    },
    getAll: (id) => {
      return request({
        url: "/website/specializedSubject/front/all",
        method: "get",
        params: {
          specializeId: id,
        },
      });
    },
  };
}
