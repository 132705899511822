import request from "@/utils/request";

/**
 * 新闻接口
 *
 */
export function newsApi() {
  return {
    getNews: () => {
      return request({
        url: "/website/news/front/list",
        method: "get",
      });
    },
    getBanne: () => {
      return request({
        url: "/website/news/front/getNewsSlideshowList",
        method: "get",
      });
    },
    getD: (id) => {
      return request({
        url: "/website/news/front/detail",
        method: "get",
        params: {
          id,
        },
      });
    },
    getAll: () => {
      return request({
        url: "/website/news/front/all",
        method: "get",
      });
    },
    getR: (id) => {
      return request({
        url: "/website/news/front/randomNews",
        method: "get",
        params: {
          id,
        },
      });
    },
  };
}
